import classNames from "classnames/bind";
import { useCallback, useState } from "react";
import { BsBug, BsLightbulb, BsThreeDots } from "react-icons/bs";

import { ReactComponent as Logo } from "../../assets/ic_logo.svg";
import { usePopupState } from "../../utils/popupUtil";
import FeedbackForm from "../FeedbackForm/FeedbackForm";

import styles from "./Header.module.scss";

const cn = classNames.bind(styles);

const Header = () => {
  const [submenuVisible, setSubmenuVisible] = useState(false);
  const { popupInfo, handleButtonClick, handleClosePopup } = usePopupState();

  const handleBugReportClick = useCallback(() => {
    handleButtonClick(
      "https://secure.aha.io/assets/idea_portals/embedded/application.js",
      "https://bug-report-hogarth.ideas.aha.io/ideas/new",
      "feedback-form--bug-report"
    );
  }, [handleButtonClick]);

  const handleFeatureRequestClick = useCallback(() => {
    handleButtonClick(
      "https://secure.aha.io/assets/idea_portals/embedded/application.js",
      "https://feature-request-hogarth.ideas.aha.io/ideas/new",
      "feedback-form--feature-request"
    );
  }, [handleButtonClick]);

  const toggleSubmenu = useCallback(() => {
    setSubmenuVisible((prevState) => !prevState);
  }, []);

  return (
    <header className={cn("header")}>
      <div className={cn("header-logo__container")}>
        <Logo className={cn("header-logo")} />
      </div>
      <div className={cn("header-client")}>Intelligent Content</div>
      <div className={cn("header-user-section")}>
        <div className={cn("header-user-more")}>
          <BsThreeDots onClick={toggleSubmenu} />
          {submenuVisible && (
            <div className={cn("header-user-more__submenu")}>
              <button
                onClick={handleBugReportClick}
              >
                <BsBug />
                Report a bug
              </button>
              <button
                onClick={handleFeatureRequestClick}
              >
                <BsLightbulb />
                Feature request
              </button>
            </div>
          )}
        </div>
      </div>
      {popupInfo && (
        <FeedbackForm
          src={popupInfo.src}
          dataPortalUrl={popupInfo.dataPortalUrl}
          popupClassName={popupInfo.popupClassname}
          onClose={handleClosePopup}
        />
      )}
    </header>
  );
};

export default Header;
