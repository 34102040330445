import classNames from "classnames/bind";
import { KeyboardEvent, useCallback } from "react";
import { BsArrowUpRight } from "react-icons/bs";

import { usePopupState } from "../../utils/popupUtil";
import FeedbackForm from "../FeedbackForm/FeedbackForm";

import styles from "./Sidebar.module.scss";

const cn = classNames.bind(styles);

const Sidebar = () => {
  const { popupInfo, handleButtonClick, handleClosePopup } = usePopupState();

  const handleBugReportClick = useCallback(() => {
    handleButtonClick(
      "https://secure.aha.io/assets/idea_portals/embedded/application.js",
      "https://bug-report-hogarth.ideas.aha.io/ideas/new",
      "feedback-form--bug-report"
    );
  }, [handleButtonClick]);

  const handleFeatureRequestClick = useCallback(() => {
    handleButtonClick(
      "https://secure.aha.io/assets/idea_portals/embedded/application.js",
      "https://feature-request-hogarth.ideas.aha.io/ideas/new",
      "feedback-form--feature-request"
    );
  }, [handleButtonClick]);

  const handleBugReportKeydown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleBugReportClick();
      }
    },
    [handleBugReportClick]
  );

  const handleFeatureRequestKeydown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter") {
        event.preventDefault();
        handleFeatureRequestClick();
      }
    },
    [handleFeatureRequestClick]
  );

  return (
    <div className={cn("sidebar")}>
      <h3>Discover Intelligent Content</h3>
      <div className={cn("sidebar-info")}>
        <span className={cn("sidebar-info__title")}>Getting started</span>
        <p>
          We have curated a selection of articles to help you discover the power of Intelligent Content and how it can
          enhance your campaigns.
        </p>
      </div>
      <ul className={cn("sidebar-list")}>
        <li>
          <span>Assemble</span>
          <p>
            Assemble is the foundation of Intelligent Content, providing a robust solution for creating and managing
            assets at scale.
          </p>
          <a href="https://hogarthww.sharepoint.com/sites/IntelligentContentHub/SitePages/Assemble.aspx">
            Learn more
            <BsArrowUpRight />
          </a>
        </li>
        <li>
          <span>Roadmap</span>
          <p>See how we’ve continuously improving our platform.</p>
          <a href="https://hogarthww.sharepoint.com/sites/IntelligentContentHub/SitePages/Roadmap.aspx">
            Learn more
            <BsArrowUpRight />
          </a>
        </li>
        <li>
          <span>Support</span>
          <p>We want to hear feedback from you or if you’re having issues with the platform.</p>
          <input
            onClick={handleBugReportClick}
            className={cn("link-like-button")}
            value="Report a bug"
            tabIndex={0}
            type="button"
            onKeyDown={handleBugReportKeydown}
          />
          &nbsp;/&nbsp;
          <input
            onClick={handleFeatureRequestClick}
            className={cn("link-like-button")}
            value="Suggest feedback"
            tabIndex={0}
            type="button"
            onKeyDown={handleFeatureRequestKeydown}
          />
          <BsArrowUpRight />
        </li>
      </ul>
      {popupInfo && (
        <FeedbackForm
          src={popupInfo.src}
          dataPortalUrl={popupInfo.dataPortalUrl}
          popupClassName={popupInfo.popupClassname}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default Sidebar;
