import classNames from "classnames/bind";
import { useCallback, useEffect, useRef } from "react";

import styles from "./FeedbackForm.module.scss";

const cn = classNames.bind(styles);

const FeedbackForm = ({ src, dataPortalUrl, popupClassName, onClose }: {
  src: string;
  dataPortalUrl: string;
  popupClassName: string;
  onClose: () => void;
}) => {
  const feedbackFormContentRef = useRef<HTMLDivElement>(null);

  const handleOverlayClick = useCallback((event: React.KeyboardEvent) => {
    if (event.key === "Escape") {
      event.preventDefault();
      onClose();
    }
  }, [onClose]);

  const stopPropagation = useCallback((event: React.MouseEvent | React.KeyboardEvent) => event.stopPropagation(), []);

  useEffect(() => {
    const currentRef = feedbackFormContentRef.current;

    if (currentRef) {
      if (!currentRef.querySelector(`script[src="${src}"]`)) {
        const script = document.createElement("script");

        script.async = true;
        script.type = "application/javascript";
        script.src = src;
        script.setAttribute("data-portal-url", dataPortalUrl);

        currentRef.appendChild(script);

        return () => {
          if (currentRef.contains(script)) {
            currentRef.removeChild(script);
          }
        };
      }
    }
  }, [src, dataPortalUrl]);

  return (
    <div className={cn("feedback-form-overlay", popupClassName)} onClick={onClose} onKeyDown={handleOverlayClick}>
      <div className={cn("feedback-form-content")} onClick={stopPropagation} onKeyDown={stopPropagation}>
        <div className={cn("feedback-form-header")}>
          <button onClick={onClose}>Close</button>
        </div>
        <div className={cn("feedback-form-iframe")} ref={feedbackFormContentRef}></div>
      </div>
    </div>
  );
};

export default FeedbackForm;
