import classNames from "classnames/bind";

import { ReactComponent as AssembleIcon } from "../../assets/assemble_icon.svg";
import { ReactComponent as ConnectIcon } from "../../assets/connect_icon.svg";
import { ReactComponent as OptimizeIcon } from "../../assets/optimize_icon.svg";

import styles from "./MainContent.module.scss";

const cn = classNames.bind(styles);

const MainContent = () => {
  return (
    <div className={cn("main-content")}>
      <h1>Hello User</h1>
      <div className={cn("main-content__subtitle")}>Create, deliver and optimise your content.</div>
      <div className={cn("main-content__info")}>
        <h2>Your apps</h2>
        <p>Below you’ll find all the applications which you have active in your current subscription.</p>
      </div>
      <div className={cn("apps")}>
        <div className={cn("app")}>
          <div className={cn("app__header")}>
            <div className={cn("app__icon")}>
              <AssembleIcon />
            </div>
            <div className={cn("app__title")}>
              <h3>Assemble</h3>
              <span>Application by Intelligent Content</span>
            </div>
          </div>
          <div className={cn("app__desc")}>
            Compile channel-ready assets tailored to individual audience and user needs through predictive adaptation,
            enabling dynamic content experiences with ease.
          </div>
          <a className={cn("button", "button--primary")} href="https://www.hoxton.co/">Launch App</a>
        </div>
        <div className={cn("app")}>
          <div className={cn("app__header")}>
            <div className={cn("app__icon")}>
              <ConnectIcon />
            </div>
            <div className={cn("app__title")}>
              <h3>Connect</h3>
              <span>Application by Intelligent Content</span>
            </div>
          </div>
          <div className={cn("app__desc")}>
            Align your assembled assets with relevant data and insights, delivering personalized communications to the
            right audience, at the right place and time.
          </div>
          <a className={cn("button", "button--primary")} href="https://www.hoxton.co/">Launch App</a>
        </div>
        <div className={cn("app")}>
          <div className={cn("app__header")}>
            <div className={cn("app__icon")}>
              <OptimizeIcon />
            </div>
            <div className={cn("app__title")}>
              <h3>Optimize</h3>
              <span>Application by Intelligent Content</span>
            </div>
          </div>
          <div className={cn("app__desc")}>
            Leverage live performance data and insights to adapt and optimize asset performance in real-time, augmenting
            user experiences for maximum impact.
          </div>
          <a className={cn("button", "button--primary")} href="https://analytics.hogarth.ai/">Launch App</a>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
