import { useCallback, useState } from "react";

interface PopupInfo {
  src: string;
  dataPortalUrl: string;
  popupClassname: string;
}

export const usePopupState = () => {
  const [popupInfo, setPopupInfo] = useState<PopupInfo | null>(null);

  const handleButtonClick = useCallback((src: string, dataPortalUrl: string, popupClassname: string) => {
    setPopupInfo({ src, dataPortalUrl, popupClassname });
  }, []);

  const handleClosePopup = useCallback(() => {
    setPopupInfo(null);
  }, []);

  return {
    popupInfo,
    handleButtonClick,
    handleClosePopup,
  };
};
